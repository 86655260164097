import { ElementRef, Injectable } from '@angular/core';
import { ModelLayer } from '@windsim-model/models/model-layer.model';
import { WindsimModule } from '@windsim/core/enums';
import { CameraViewingFrustum, CanvasSize } from '@windsim/core/models';
import * as THREE from 'three';
import { BufferGeometry, Mesh, Vector3 } from 'three';
import { ModelLegendService } from './model-legend.service';
import { ModelShaderService } from './model-shader.service';
// eslint-disable-next-line import/extensions
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// eslint-disable-next-line import/extensions
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { MeshData } from '@windsim-model/models';
import { ModelFileType } from '@windsim-model/enums';

@Injectable({
  providedIn: 'root',
})
export class ThreeService {
  public constructor(private modelLegendService: ModelLegendService, private modelShaderService: ModelShaderService) {
    this.render = this.render.bind(this);
  }

  public clientId: string;
  public projectId: string;
  public module: WindsimModule;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.OrthographicCamera;
  public scene: THREE.Scene;
  private controls: OrbitControls;
  private viewSize = 10000;
  private highestLayer = 0;
  private canvas: ElementRef<HTMLCanvasElement>;
  private lineBasicMaterial = new THREE.LineBasicMaterial({
    color: 0x2c2c2c,
    // flatShading: true,
    depthTest: false,
  });

  private static saveFile(strData, filename) {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      document.body.appendChild(link);
      link.download = filename;
      link.href = strData;
      link.click();
      document.body.removeChild(link);
    } else {
      window.location.replace('');
    }
  }

  private static createGeometry(meshData: MeshData): THREE.BufferGeometry {
    const geometry = new THREE.BufferGeometry();
    const colors = [];
    const points = meshData.nodes.map((node, i) => {
      if (meshData.nodesValues) {
        colors.push(meshData.nodesValues[i][0]);
        colors.push(meshData.nodesValues[i][1]);
        colors.push(meshData.nodesValues[i][2]);
      }
      return new THREE.Vector3(node.x, node.z, -node.y);
    });
    const positions = [];
    meshData.connections.forEach((c) => {
      positions.push(c[0] - 1);
      positions.push(c[1] - 1);
      positions.push(c[2] - 1);

      positions.push(c[0] - 1);
      positions.push(c[3] - 1);
      positions.push(c[2] - 1);
    });

    geometry.setFromPoints(points);
    geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(colors), 3));
    geometry.setIndex(positions);

    geometry.computeVertexNormals();
    geometry.center();
    return geometry;
  }

  private static createPoints(meshData: MeshData): Array<THREE.Vector3> {
    const points = [];
    meshData.connections.forEach((connection) => {
      const nodeAIndex = connection[0] - 1;
      const noteBIndex = connection[1] - 1;
      const nodeCIndex = connection[2] - 1;
      const nodeDIndex = connection[3] - 1;
      const nodeAValue = meshData.nodes[nodeAIndex];
      const nodeBValue = meshData.nodes[noteBIndex];
      const nodeCValue = meshData.nodes[nodeCIndex];
      const nodeDValue = meshData.nodes[nodeDIndex];
      points.push(
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
        new THREE.Vector3(nodeBValue.x, nodeBValue.z, -nodeBValue.y),
        new THREE.Vector3(nodeCValue.x, nodeCValue.z, -nodeCValue.y),
        new THREE.Vector3(nodeDValue.x, nodeDValue.z, -nodeDValue.y),
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
      );
    });
    return points;
  }

  private createPointsForOpenArea(meshData: MeshData): Array<Array<THREE.Vector3>> {
    const firstLineConnections = meshData.connections.slice(meshData.parts[0].begin - 1, meshData.parts[0].end - 1);
    const firstLine = [];
    firstLineConnections.forEach((connection) => {
      const nodeAIndex = connection[0] - 1;
      const nodeBIndex = connection[1] - 1;
      const nodeCIndex = connection[2] - 1;
      const nodeDIndex = connection[3] - 1;
      const nodeAValue = meshData.nodes[nodeAIndex];
      const nodeBValue = meshData.nodes[nodeBIndex];
      const nodeCValue = meshData.nodes[nodeCIndex];
      const nodeDValue = meshData.nodes[nodeDIndex];
      firstLine.push(
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
        new THREE.Vector3(nodeBValue.x, nodeBValue.z, -nodeBValue.y),
      );
      firstLine.push(
        new THREE.Vector3(nodeBValue.x, nodeBValue.z, -nodeBValue.y),
        new THREE.Vector3(nodeCValue.x, nodeCValue.z, -nodeCValue.y),
      );
      firstLine.push(
        new THREE.Vector3(nodeCValue.x, nodeCValue.z, -nodeCValue.y),
        new THREE.Vector3(nodeDValue.x, nodeDValue.z, -nodeDValue.y),
      );
    });

    const firstGridConnections = meshData.connections.slice(meshData.parts[1].begin - 1, meshData.parts[1].end - 1);

    const firstGrid = [];
    firstGridConnections.forEach((connection) => {
      const nodeAIndex = connection[0] - 1;
      const noteBIndex = connection[1] - 1;
      const nodeCIndex = connection[2] - 1;
      const nodeDIndex = connection[3] - 1;
      const nodeAValue = meshData.nodes[nodeAIndex];
      const nodeBValue = meshData.nodes[noteBIndex];
      const nodeCValue = meshData.nodes[nodeCIndex];
      const nodeDValue = meshData.nodes[nodeDIndex];
      firstGrid.push(
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
        new THREE.Vector3(nodeBValue.x, nodeBValue.z, -nodeBValue.y),
        new THREE.Vector3(nodeCValue.x, nodeCValue.z, -nodeCValue.y),
        new THREE.Vector3(nodeDValue.x, nodeDValue.z, -nodeDValue.y),
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
      );
    });

    const secondLineConnections = meshData.connections.slice(meshData.parts[2].begin - 1, meshData.parts[2].end - 1);
    const secondLine = [];
    secondLineConnections.forEach((connection) => {
      const nodeAIndex = connection[0] - 1;
      const nodeBIndex = connection[1] - 1;
      const nodeCIndex = connection[2] - 1;
      const nodeDIndex = connection[3] - 1;
      const nodeAValue = meshData.nodes[nodeAIndex];
      const nodeBValue = meshData.nodes[nodeBIndex];
      const nodeCValue = meshData.nodes[nodeCIndex];
      const nodeDValue = meshData.nodes[nodeDIndex];
      secondLine.push(
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
        new THREE.Vector3(nodeBValue.x, nodeBValue.z, -nodeBValue.y),
      );
      secondLine.push(
        new THREE.Vector3(nodeBValue.x, nodeBValue.z, -nodeBValue.y),
        new THREE.Vector3(nodeCValue.x, nodeCValue.z, -nodeCValue.y),
      );
      secondLine.push(
        new THREE.Vector3(nodeCValue.x, nodeCValue.z, -nodeCValue.y),
        new THREE.Vector3(nodeDValue.x, nodeDValue.z, -nodeDValue.y),
      );
    });

    const secondGridConnections = meshData.connections.slice(meshData.parts[3].begin - 1, meshData.parts[3].end - 1);
    const secondGrid = [];
    secondGridConnections.forEach((connection) => {
      const nodeAIndex = connection[0] - 1;
      const noteBIndex = connection[1] - 1;
      const nodeCIndex = connection[2] - 1;
      const nodeDIndex = connection[3] - 1;
      const nodeAValue = meshData.nodes[nodeAIndex];
      const nodeBValue = meshData.nodes[noteBIndex];
      const nodeCValue = meshData.nodes[nodeCIndex];
      const nodeDValue = meshData.nodes[nodeDIndex];
      secondGrid.push(
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
        new THREE.Vector3(nodeBValue.x, nodeBValue.z, -nodeBValue.y),
        new THREE.Vector3(nodeCValue.x, nodeCValue.z, -nodeCValue.y),
        new THREE.Vector3(nodeDValue.x, nodeDValue.z, -nodeDValue.y),
        new THREE.Vector3(nodeAValue.x, nodeAValue.z, -nodeAValue.y),
      );
    });
    return [firstLine, firstGrid, secondLine, secondGrid];
  }

  // RENDERING INITIALIZATION
  public startRendering(canvas: ElementRef<HTMLCanvasElement>): void {
    this.createScene();
    this.canvas = canvas;
    this.createOrthographicCamera();
    this.createRenderer(canvas);

    const service: ThreeService = this;
    (function render() {
      service.render();
    })();
    this.addControls();
    window.addEventListener('resize', this.onResize.bind(this), false);
  }

  private createScene(): void {
    this.scene = new THREE.Scene();
  }

  private createOrthographicCamera() {
    const cameraViewingFrustum = this.getCameraViewingFrustum();
    this.camera = new THREE.OrthographicCamera(
      cameraViewingFrustum.left,
      cameraViewingFrustum.right,
      cameraViewingFrustum.top,
      cameraViewingFrustum.bottom,
      0.1,
      100000,
    );

    this.camera.position.y = 14000;
    this.camera.lookAt(0, 0, 0);
    this.camera.updateProjectionMatrix();
  }

  private createRenderer(canvas: ElementRef<HTMLCanvasElement>) {
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvas.nativeElement,
      alpha: false,
      antialias: true,
      preserveDrawingBuffer: true,
      powerPreference: 'high-performance',
    });
    this.renderer.setSize(this.canvasSize.width, this.canvasSize.height);
    this.renderer.setClearColor('#ffffff');
    this.renderer.autoClear = false;
  }

  public render() {
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
  }

  private addControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.rotateSpeed = 1.0;
    this.controls.enableRotate = true;
    this.controls.zoomSpeed = 1.0;
    this.controls.enableZoom = true;
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };
    this.controls.addEventListener('change', this.render.bind(this));
  }

  private get canvasSize(): CanvasSize {
    return {
      width: this.canvas.nativeElement.clientWidth,
      height: this.canvas.nativeElement.clientHeight,
    };
  }

  private getCameraViewingFrustum(): CameraViewingFrustum {
    const horizontalAspectRatio = this.canvasSize.width / this.canvasSize.height;
    return {
      top: this.viewSize / 2.0,
      bottom: this.viewSize / -2.0,
      left: (this.viewSize * horizontalAspectRatio) / -2.0,
      right: (this.viewSize * horizontalAspectRatio) / 2.0,
    };
  }

  public onResize() {
    const cameraViewingFrustum = this.getCameraViewingFrustum();
    this.camera.left = cameraViewingFrustum.left;
    this.camera.right = cameraViewingFrustum.right;
    this.camera.top = cameraViewingFrustum.top;
    this.camera.bottom = cameraViewingFrustum.bottom;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.canvasSize.width, this.canvasSize.height);
    this.render();
  }

  private modelLoader(model: ArrayBuffer): Promise<GLTF> {
    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.parse(model, '', (data) => resolve(data), reject);
    });
  }

  public async loadGltf(layer: ModelLayer, data: ArrayBuffer): Promise<void> {
    const gltf = await this.modelLoader(data);
    const model = gltf.scene;
    model.traverse((o) => {
      if (o instanceof Mesh) {
        o.material = this.defineMaterial(o.geometry, layer);
      }
    });

    if (this.scene.getObjectByName(layer.layerName.split('/').pop().split('.')[0]) === undefined) {
      this.scene.add(model);
      const object = this.scene.getObjectByName(layer.layerName.split('/').pop().split('.')[0]) as THREE.Mesh;
      if (object) {
        object.visible = false;
        object.geometry.center();
        object.rotateX(3.14159);
      }
    }
  }

  public rearrangeLayers(storedLayers: ModelLayer[]): void {
    let position = 1;
    storedLayers.forEach((layer) => {
      const object = this.scene.getObjectByName(layer.layerName) as THREE.Mesh;
      const bbox = new THREE.Vector3();
      if (object) {
        object.geometry.boundingBox.getSize(bbox);
        object.position.setY(0);
        object.translateY(-(bbox.y - this.highestLayer * position * 5));
      }
      position += 1;
    });
    this.render();
  }

  public changeLayerOpacity(layer: ModelLayer) {
    const object = this.scene.getObjectByName(layer.layerName) as THREE.Mesh;
    const { geometry } = object;
    object.material = this.defineMaterial(geometry, layer);
    this.render();
  }

  public showLayer(layerName: string) {
    const object = this.scene.getObjectByName(layerName) as THREE.Mesh;
    if (object) {
      object.visible = true;
      this.render();
    }
  }

  public hideLayer(layerName: string) {
    const object = this.scene.getObjectByName(layerName) as THREE.Mesh;
    if (object) {
      object.visible = false;
      this.render();
    }
  }

  public isLayerAlreadyLoaded(layerName: string): boolean {
    const object = this.scene.getObjectByName(layerName) as THREE.Mesh;
    return !!object;
  }

  private defineMaterial(geometry: THREE.BufferGeometry, layer: ModelLayer): THREE.Material {
    const material = this.modelShaderService.getShaderMaterial(layer, geometry);
    material.blending = THREE.CustomBlending;
    material.blendEquation = THREE.AddEquation;
    material.blendSrc = THREE.SrcAlphaFactor;
    material.blendDst = THREE.OneMinusSrcAlphaFactor;
    return material;
  }

  // SCREEN-SHOTS
  public takeScreenshot(legendCanvasElement: HTMLCanvasElement) {
    const canvasToPrint = document.createElement('canvas');
    canvasToPrint.width = this.renderer.domElement.width;
    canvasToPrint.height = this.renderer.domElement.height;
    const canvasToPrintContext = canvasToPrint.getContext('2d');
    canvasToPrintContext.drawImage(this.renderer.domElement, 0, 0);
    canvasToPrintContext.drawImage(legendCanvasElement, 10, 10);
    ThreeService.saveFile(canvasToPrint.toDataURL().replace('image/png', 'image/octet-stream'), 'windsim_terrain.png');
  }

  // GEOMETRY AND LAYERS
  public addLayer(meshData: MeshData, layer: ModelLayer, x?: number, y?: number): void {
    switch (layer.fileType) {
      case ModelFileType.GridXY:
        this.addGridXYLayer(meshData, layer, x, y);
        break;
      case ModelFileType.GridZ:
        this.addGridZLayer(meshData, layer);
        break;
      case ModelFileType.OpenArea:
        this.addOpenAreaLayer(meshData, layer);
        break;
      case ModelFileType.Roughness:
      case ModelFileType.RoughnessLog:
      case ModelFileType.Inclination:
      case ModelFileType.SecondOrderDerivative:
      case ModelFileType.FieldElevation:
      case ModelFileType.Terrain:
      case ModelFileType.DeltaElevation:
      case ModelFileType.Elevation:
        this.addModelTerrainLayer(meshData, layer);
        break;
      default:
        break;
    }
    this.render();
  }

  private addModelTerrainLayer(meshData: MeshData, layer: ModelLayer): void {
    const geometry = ThreeService.createGeometry(meshData);
    const material = this.defineMaterial(geometry, layer);
    const meshGeometry = new THREE.Mesh(geometry, material);
    meshGeometry.name = layer.layerName;
    meshGeometry.visible = layer.isVisible;

    const bbox = new Vector3();
    meshGeometry.geometry.boundingBox.getSize(bbox);
    this.storeHighestLayer(bbox.y);
    this.scene.add(meshGeometry);
  }

  private addGridXYLayer(meshData: MeshData, layer: ModelLayer, x: number, y: number): void {
    const points = ThreeService.createPoints(meshData);

    const mesh = new THREE.Mesh();
    mesh.name = layer.layerName;
    mesh.visible = layer.isVisible;
    const pointsPerGrid = 5;

    if (x >= y) {
      for (let i = 0; i < y; i++) {
        let index = i * x;

        const begin = index * pointsPerGrid;
        index = i * (x - 1) + x - 1;
        const end = index * pointsPerGrid;
        const end2 = end + (i + 1) * pointsPerGrid;

        const geometry = new BufferGeometry().setFromPoints(points.slice(begin, end));
        const grid = new THREE.Line(geometry, this.lineBasicMaterial);
        mesh.add(grid);
        const geometry2 = new BufferGeometry().setFromPoints(points.slice(end, end2));
        const grid2 = new THREE.Line(geometry2, this.lineBasicMaterial);
        mesh.add(grid2);
      }
    }
    if (x < y) {
      for (let i = 0; i < y; i++) {
        const index = i * (x - 1);
        const begin = index * pointsPerGrid;
        const end = (index + x - 1) * pointsPerGrid;
        const geometry = new BufferGeometry().setFromPoints(points.slice(begin, end));
        const grid = new THREE.Line(geometry, this.lineBasicMaterial);
        mesh.add(grid);
      }
    }

    this.scene.add(mesh);

    const center = new THREE.Vector3();
    const box = new THREE.BoxHelper(mesh, 0xffff00);
    box.geometry.computeBoundingBox();
    box.geometry.boundingBox.getCenter(center);
    box.geometry.center();
    const obj = this.scene.getObjectByName(layer.layerName);
    obj.position.set(-center.x, center.y, -center.z);
  }

  private addGridZLayer(meshData: MeshData, layer: ModelLayer): void {
    const points = ThreeService.createPoints(meshData);
    const mesh = new THREE.Mesh();
    mesh.name = layer.layerName;
    mesh.visible = layer.isVisible;
    const numberZCells = 30;
    const polygonsPerCell = 15;
    let begin = 0;

    for (let i = 0; i < numberZCells; i++) {
      begin = i * polygonsPerCell;
      const end = begin + polygonsPerCell;
      const geometry = new BufferGeometry().setFromPoints(points.slice(begin, end));
      const row = new THREE.Line(geometry, this.lineBasicMaterial);
      mesh.add(row);
    }
    const offset = meshData.header.numberOfPolygons / numberZCells;
    const plotOffset = polygonsPerCell * numberZCells;

    const iterations = numberZCells * 2;
    for (let i = 0; i < iterations; i++) {
      begin = plotOffset + i * offset;
      const end = plotOffset + offset * i + offset;
      const geometry = new BufferGeometry().setFromPoints(points.slice(begin, end));
      const row = new THREE.Line(geometry, this.lineBasicMaterial);
      mesh.add(row);
    }
    mesh.scale.set(3, 3, 3);
    this.scene.add(mesh);

    const center = new THREE.Vector3();
    const box = new THREE.BoxHelper(mesh, 0xffff00);
    box.geometry.computeBoundingBox();
    box.geometry.boundingBox.getCenter(center);
    box.geometry.center();
    const obj = this.scene.getObjectByName(layer.layerName);
    obj.position.set(-center.x, center.y, -center.z);
  }

  private addOpenAreaLayer(meshData: MeshData, layer: ModelLayer): void {
    const points = this.createPointsForOpenArea(meshData);
    const boldLine = new THREE.LineBasicMaterial({
      color: 0xe3470e,
      opacity: 1,
      side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh();
    mesh.name = layer.layerName;
    mesh.visible = layer.isVisible;

    // first line
    let geometry = new BufferGeometry().setFromPoints(points[0]);
    let row = new THREE.Line(geometry, boldLine);
    mesh.add(row);

    // first grid
    geometry = new BufferGeometry().setFromPoints(points[1]);
    row = new THREE.Line(geometry, this.lineBasicMaterial);
    mesh.add(row);

    // second line

    geometry = new BufferGeometry().setFromPoints(points[2]);
    row = new THREE.Line(geometry, boldLine);
    mesh.add(row);

    // second grid
    geometry = new BufferGeometry().setFromPoints(points[3]);
    row = new THREE.Line(geometry, this.lineBasicMaterial);
    mesh.add(row);

    this.scene.add(mesh);

    const center = new THREE.Vector3();
    const box = new THREE.BoxHelper(mesh, 0xffff00);
    box.geometry.computeBoundingBox();
    box.geometry.boundingBox.getCenter(center);
    box.geometry.center();
    const obj = this.scene.getObjectByName(layer.layerName);
    obj.position.set(-center.x, center.y, -center.z);
  }

  private storeHighestLayer(maxValue: number): void {
    if (this.highestLayer < maxValue) {
      this.highestLayer = maxValue;
    }
  }
}
