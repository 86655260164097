import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DevToolsComponent } from '@windsim-shared/core/dev-tools/dev-tools.component';
import { BaseComponent } from '@windsim-shared/core/base/base.component';
import { AssignModuleRoleComponent, AssignUserRoleComponent, ConfirmationDialogComponent } from '@windsim-shared/dialogs';
import { AssignProjectRoleComponent } from '@windsim-shared/dialogs/assign-project-role/assign-project-role.component';
import { SetProjectVisibilityComponent } from '@windsim-shared/dialogs/set-project-visibility/set-project-visibility.component';
import { PageLayoutComponent } from '@windsim-shared/layout';
import { CanvasComponent, DialogComponent, InfoBoxComponent, LayerToggleComponent, SidenavComponent } from '@windsim-shared/ui-elements';
import { CoordinatesControlComponent } from '@windsim-shared/ui-elements/coordinates-control/coordinates-control.component';
import { ForestControlComponent } from '@windsim-shared/ui-elements/forest-control/forest-control.component';
import { CoordinatesPointComponent } from '@windsim-shared/ui-elements/coordinates-point/coordinates-point.component';
import {
  HumanReadableProjectRolePipe,
  HumanReadableProjectTypePipe,
  HumanReadableProjectVisibilityPipe,
  HumanReadableJobStatusPipe,
  HumanReadableProjectStatusPipe,
  HumanReadableUserRolePipe,
  ProjectStatusCssClassPipe,
  AlertCssClassPipe,
  RemoveCommaPipe,
  SignalrStatusColorPipe,
  WithStatusPipe,
  MultilineUnicodePipe,
  LogMessageFormatPipe,
} from '@windsim/core/pipes';
import { HumanReadableModulePipe } from '@windsim/core/pipes/human-readable-module.pipe';
import { BaseTabularDataComponent } from '@windsim/shared/components/core/base-tabular-data/base-tabular-data.component';
import { MenuComponent } from '@windsim/shared/components/layout/menu/menu.component';
import { AlertComponent } from '@windsim/shared/components/ui-elements/alert/alert.component';
import { ContentViewerComponent } from '@windsim/shared/components/ui-elements/content-viewer/content-viewer.component';
import { IconComponent } from '@windsim/shared/components/ui-elements/icon/icon.component';
import { LanguagePickerComponent } from '@windsim/shared/components/ui-elements/language-picker/language-picker.component';
import { LoaderComponent } from '@windsim/shared/components/ui-elements/loader/loader.component';
import { MobileAlertComponent } from '@windsim/shared/components/ui-elements/mobile-alert/mobile-alert.component';
import { NumericalControlComponent } from '@windsim/shared/components/ui-elements/numerical-control/numerical-control.component';
import { NgChartsModule } from 'ng2-charts';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BaseProjectsComponent } from './components/core/base-projects/base-projects.component';
import { PageLayoutSimpleComponent } from './components/layout/page-layout-simple/page-layout-simple.component';
import { LoaderContainerComponent } from './components/ui-elements/loader-container/loader-container.component';
import { SidenavMenuComponent } from './components/ui-elements/sidenav-menu/sidenav-menu.component';
import { TableSearchComponent } from './components/ui-elements/table-search/table-search.component';
import { UploadComponent } from './components/ui-elements/upload/upload.component';
import { PhoneNumberControlComponent } from './components/ui-elements/phone-number-control/phone-number-control.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { ShowOnDirtyAnTouchedErrorStateMatcher } from '@windsim/core/error-matchers';
import { BaseEditFormComponent } from './components/core/base-edit-form/base-edit-form.component';
import { PasswordValidatorComponent } from '@windsim-shared/ui-elements/password-validator/password-validator.component';
import { PasswordVisibilityToggleComponent } from '@windsim-shared/ui-elements/password-visibility-toggle/password-visibility-toggle.component';
import { SidenavMenuLinkStatusPipe } from '@windsim/core/pipes/sidenav-menu-link-status.pipe';
import { JobWorkflowStatusComponent } from './components/ui-elements/job-workflow-status/job-workflow-status.component';
import { EnvironmentInfoComponent } from './components/ui-elements/environment-info/environment-info.component';
import { NumberTruncatePipe } from '@windsim/core/pipes/number-truncate.pipe';
import { SubscriptionRenewalDatePipe } from '@windsim/core/pipes/subscription-renewal-date.pipe';
import { CustomPaginatorIntlService } from '@windsim-core/services/custom-paginator-intl.service';
import { MatPaginatorIntl } from '@angular/material/paginator';

@NgModule({
  declarations: [
    CoordinatesControlComponent,
    CoordinatesPointComponent,
    ForestControlComponent,
    PageLayoutComponent,
    SidenavComponent,
    ConfirmationDialogComponent,
    NumericalControlComponent,
    InfoBoxComponent,
    LayerToggleComponent,
    BaseComponent,
    CanvasComponent,
    LoaderComponent,
    BaseTabularDataComponent,
    AlertComponent,
    IconComponent,
    ContentViewerComponent,
    MobileAlertComponent,
    LanguagePickerComponent,
    MenuComponent,
    DevToolsComponent,
    AssignProjectRoleComponent,
    SidenavMenuComponent,
    PageLayoutSimpleComponent,
    SetProjectVisibilityComponent,
    TableSearchComponent,
    AssignModuleRoleComponent,
    BaseProjectsComponent,
    WithStatusPipe,
    DialogComponent,
    RemoveCommaPipe,
    LoaderContainerComponent,
    UploadComponent,
    HumanReadableProjectTypePipe,
    HumanReadableProjectVisibilityPipe,
    HumanReadableJobStatusPipe,
    HumanReadableProjectStatusPipe,
    HumanReadableProjectRolePipe,
    HumanReadableUserRolePipe,
    HumanReadableModulePipe,
    SignalrStatusColorPipe,
    ProjectStatusCssClassPipe,
    AlertCssClassPipe,
    PhoneNumberControlComponent,
    BaseEditFormComponent,
    PasswordVisibilityToggleComponent,
    PasswordValidatorComponent,
    SidenavMenuLinkStatusPipe,
    AssignUserRoleComponent,
    JobWorkflowStatusComponent,
    EnvironmentInfoComponent,
    NumberTruncatePipe,
    MultilineUnicodePipe,
    LogMessageFormatPipe,
    SubscriptionRenewalDatePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SimplebarAngularModule,
    MatExpansionModule,
    MatSidenavModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    NgChartsModule,
    MatSnackBarModule,
  ],
  exports: [
    TranslateModule,
    CoordinatesControlComponent,
    CoordinatesPointComponent,
    ForestControlComponent,
    ContentViewerComponent,
    PageLayoutComponent,
    NumericalControlComponent,
    InfoBoxComponent,
    LayerToggleComponent,
    ConfirmationDialogComponent,
    LoaderComponent,
    CanvasComponent,
    SidenavComponent,
    SidenavMenuComponent,
    MobileAlertComponent,
    LanguagePickerComponent,
    MenuComponent,
    IconComponent,
    AlertComponent,
    SimplebarAngularModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    DevToolsComponent,
    PageLayoutSimpleComponent,
    TableSearchComponent,
    WithStatusPipe,
    DialogComponent,
    RemoveCommaPipe,
    LoaderContainerComponent,
    UploadComponent,
    HumanReadableProjectTypePipe,
    HumanReadableProjectVisibilityPipe,
    HumanReadableJobStatusPipe,
    HumanReadableProjectStatusPipe,
    HumanReadableProjectRolePipe,
    HumanReadableUserRolePipe,
    HumanReadableModulePipe,
    SignalrStatusColorPipe,
    ProjectStatusCssClassPipe,
    AlertCssClassPipe,
    PhoneNumberControlComponent,
    PasswordValidatorComponent,
    PasswordVisibilityToggleComponent,
    SidenavMenuLinkStatusPipe,
    JobWorkflowStatusComponent,
    NumberTruncatePipe,
    MultilineUnicodePipe,
    LogMessageFormatPipe,
    SubscriptionRenewalDatePipe,
  ],
  providers: [
    RemoveCommaPipe,
    HumanReadableProjectTypePipe,
    HumanReadableJobStatusPipe,
    HumanReadableProjectStatusPipe,
    HumanReadableProjectRolePipe,
    HumanReadableUserRolePipe,
    HumanReadableModulePipe,
    ProjectStatusCssClassPipe,
    MultilineUnicodePipe,
    LogMessageFormatPipe,
    SubscriptionRenewalDatePipe,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyAnTouchedErrorStateMatcher },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntlService },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
