import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@windsim-shared/core';
import { Animations } from '@windsim/core/animations';
import { CommonDefaults } from '@windsim/core/configs';
import { ProjectAccessPolicy } from '@windsim/core/models';
import { AuthorizationService, StorageService } from '@windsim/core/services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-forest-control',
  templateUrl: './forest-control.component.html',
  styleUrls: ['./forest-control.component.scss'],
  animations: [Animations.inOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForestControlComponent extends BaseComponent implements OnInit {
  @Input() counter: number;
  @Input() roughnessFormControl: FormControl;
  @Input() heightFormControl: FormControl;
  @Input() resistiveForceC2FormControl: FormControl;
  @Input() turbulenceFormControl: FormControl;
  @Input() ForestCellsZFormControl: FormControl;
  @Input() isReadonly = false;
  clientId: string;
  projectId: string;

  projectAccessPolicy = new BehaviorSubject<ProjectAccessPolicy>(CommonDefaults.DEFAULT_PROJECT_ROLE);

  constructor(
    public translate: TranslateService,
    private readonly storageService: StorageService,
    private readonly authorizationService: AuthorizationService,
    private readonly route: ActivatedRoute,
  ) {
    super();
  }

  get isValid(): boolean {
    return (
      this.roughnessFormControl.errors === null &&
      this.heightFormControl.errors === null &&
      this.resistiveForceC2FormControl === null &&
      this.ForestCellsZFormControl.errors === null
    );
  }

  get roughnessErrorMessage(): string {
    const { errors } = this.roughnessFormControl;

    if (!errors) {
      return '';
    }
    switch (true) {
      case errors.required !== undefined:
        return translationMarker('terrain.error-roughness-required');
      case errors.min !== undefined || errors.max !== undefined:
        return translationMarker('terrain.error-roughness-values');
      case errors.greaterThan !== undefined:
        return translationMarker('terrain.error-roughness-values-greater-than');
      case errors.smallerThan !== undefined:
        return translationMarker('terrain.error-roughness-values-smaller-than');
      default:
        return '';
    }
  }

  get heightErrorMessage(): string {
    const { errors } = this.heightFormControl;

    if (!errors) {
      return '';
    }
    switch (true) {
      case errors.required !== undefined:
        return translationMarker('terrain.error-height-required');
      case errors.min !== undefined || errors.max !== undefined:
        return translationMarker('terrain.error-height-values');
      case errors.greaterThan !== undefined:
        return translationMarker('terrain.error-height-values-greater-than');
      case errors.smallerThan !== undefined:
        return translationMarker('terrain.error-height-values-smaller-than');
      default:
        return '';
    }
  }

  get resistiveForceC2ErrorMessage(): string {
    const { errors } = this.resistiveForceC2FormControl;

    if (!errors) {
      return '';
    }
    switch (true) {
      case errors.required !== undefined:
        return translationMarker('terrain.error-resistiveForceC2-required');
      case errors.min !== undefined || errors.max !== undefined:
        return translationMarker('terrain.error-resistiveForceC2-values');
      case errors.greaterThan !== undefined:
        return translationMarker('terrain.error-resistiveForceC2-values-greater-than');
      case errors.smallerThan !== undefined:
        return translationMarker('terrain.error-resistiveForceC2-values-smaller-than');
      default:
        return '';
    }
  }

  get ForestCellsZErrorMessage(): string {
    const { errors } = this.ForestCellsZFormControl;

    if (!errors) {
      return '';
    }
    switch (true) {
      case errors.required !== undefined:
        return translationMarker('terrain.error-ForestCellsZ-required');
      case errors.min !== undefined || errors.max !== undefined:
        return translationMarker('terrain.error-ForestCellsZ-values');
      case errors.greaterThan !== undefined:
        return translationMarker('terrain.error-ForestCellsZ-values-greater-than');
      case errors.smallerThan !== undefined:
        return translationMarker('terrain.error-ForestCellsZ-values-smaller-than');
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.clientId = this.storageService.getGlobalValue(CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.route.paramMap.subscribe((params) => {
      this.projectId = params.get('projectId');
    });
  }

  get className(): string {
    switch (true) {
      case !this.isValid:
        return 'forest-control--invalid';
      case this.isReadonly:
        this.disableFormControls();
        return 'forest-control--readonly';
      default:
        this.enableFormControls();
        return '';
    }
  }

  private disableFormControls(): void {}

  private enableFormControls(): void {}
}
